import React from "react";
import HomeImage from "../images/home-img.png";
import AboutImage from "../images/about-img-home.png";
import { Link } from "react-router-dom";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Volunteer from "../components/Volunteer";
import EventComp from "../components/EventComp";
import { services } from "../data";
import { motion } from "framer-motion";
import {
	fadeUpVariant,
	leftVariant,
	navVariants,
	opacityVariant,
	rightVariant,
	staggerContainer,
} from "../utils/motion";
import VideoComp from "../components/Video";

const Home = () => {
	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "spring", ease: "easeIn", duration: 1 }}
			className="mt-28 lg:mt-36"
		>
			{/* HOME SECTION STARTS */}
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="home__container main__container lg:grid grid-cols-2 lg:gap-12 xl:gap-20 lg:justify-center lg:items-center"
			>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
				>
					<motion.h1
						variants={fadeUpVariant(0.4)}
						className="text-4xl lg:text-5xl xl:text-6xl text-[#263734] font-semibold uppercase"
					>
						Changing the world - one child at a time.
					</motion.h1>
					<motion.p
						variants={fadeUpVariant(0.6)}
						className="mt-4 lg:mt-8 text-[#747474] lg:text-lg xl:leading-8"
					>
						At Kids First International, our single-minded focus and unyielding
						dedication to our mission to assist, empower, and humanize children
						is our greatest strength. It drives us to push boundaries, challenge
						the status quo, and constantly seek innovative ways to make a
						positive impact on the lives of children worldwide.
					</motion.p>
					<motion.div
						variants={fadeUpVariant(0.8)}
						className="pont__list flex items-center gap-[1.5rem] mt-6 lg:mt-8"
					>
						<span className="block uppercase text-[#263734] text-sm lg:text-[1rem] font-semibold">
							food
						</span>
						<div className="point__circle w-[12px] h-[12px] bg-[#E91E63] rounded-full"></div>
						<span className="block uppercase text-[#263734] text-sm lg:text-[1rem] font-semibold">
							education
						</span>
						<div className="point__circle w-[12px] h-[12px] bg-[#E91E63] rounded-full"></div>
						<span className="block uppercase text-[#263734] text-sm lg:text-[1rem] font-semibold">
							asylum
						</span>
					</motion.div>
					<motion.div
						variants={fadeUpVariant(1)}
						className="home__btn mt-8 lg:mt-16"
					>
						<Link
							to="/donate"
							className="bg-[#00715D] inline-flex text-white font-semibold py-[15px] px-[20px] mr-4"
						>
							Donate Now
						</Link>
						<Link
							to="/about-us"
							className="bg-transparent p-0 text-[#00715D] font-semibold"
						>
							About Us
						</Link>
					</motion.div>
				</motion.div>
				<motion.div
					variants={opacityVariant(1.3)}
					className="home__img mt-10 lg:mt-[unset]"
				>
					<img src={HomeImage} alt="Kids 1st International" />
				</motion.div>
			</motion.div>
			{/* HOME SECTION ENDS */}

			{/* GREEN SUB-SECTION STARTS */}
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
			>
				<motion.div
					variants={navVariants}
					className="mt-20 lg:mt-32 bg-[#00715D] px-[1.4rem] pt-6 pb-12 main__container lg:flex lg:justify-between lg:items-center lg:p-12"
				>
					<h2 className="text-3xl lg:text-4xl xl:text-5xl text-white font-semibold lg:max-w-[40%]">
						Let’s Help Out With Your Charity
					</h2>
					<div className="mt-8 lg:mt-[unset]">
						<Link
							to="/donate"
							className="bg-[#FDD65B] text-black font-semibold py-[15px] px-[20px] mr-4"
						>
							Donate Now
						</Link>
						<Link
							to="/contact-us"
							className="bg-[#FDD65B] text-black font-semibold py-[15px] px-[20px] mr-4"
						>
							Volunteer
						</Link>
					</div>
				</motion.div>
			</motion.div>
			{/* GREEN SUB-SECTION ENDS */}

			{/* ABOUT US SECTION STARTS */}
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="about__container mt-20 mb-40 lg:mt-32 main__container lg:grid lg:grid-cols-2 lg:gap-8 xl:gap-12 lg:justify-center lg:items-center"
			>
				<motion.div variants={leftVariant(0.2)}>
					<img src={AboutImage} alt="Kids 1st International" />
				</motion.div>

				<motion.div variants={rightVariant(0.2)} className="mt-8">
					<span className="uppercase tracking-widest text-sm text-[#D9A503] block font-semibold mb-2">
						About Us
					</span>

					<h2 className="text-3xl lg:text-4xl text-[#263734] font-semibold mb-5">
						Our single-minded focus to assist, empower and humanize children is
						our greatest strength.
					</h2>

					<p className="text-[#747474]">
						We leverage this devotion by making sure they have the ability to
						attend school without an empty belly calling them back into the
						streets, providing books and educational material that too many of
						their schools lack and giving them assurance that they are NOT
						alone.
					</p>

					<div className="bg-[#00715D] p-6 mt-6 mb-12">
						<h3 className="text-2xl text-white font-semibold">
							You should consider joining the movement. The kids need you.
						</h3>
					</div>

					<Link
						to="/donate"
						className="bg-[#FDD65B] text-black font-semibold py-[15px] px-[50px] mr-4"
					>
						Donate Now
					</Link>
				</motion.div>
			</motion.div>
			{/* ABOUT US SECTION ENDS */}

			<VideoComp />

			{/* WHAT WE DO SECTION STARTS */}
			<Services
				title="What We Do"
				titleColor="text-white"
				subTitleColor="text-[#FDD65B]"
				bgColor="bg-[#00715D]"
				servicesData={services}
				showAll={false}
				showLink={true}
				mt="mt-20"
				lgmt="lg:mt-32"
			/>
			{/* WHAT WE DO SECTION ENDS */}

			{/* GET INVOLVED SECTION STARTS */}
			<Volunteer />
			{/* GET INVOLVED SECTION ENDS */}

			{/* EVENTS SECTION STARTS */}
			<EventComp />
			{/* EVENTS SECTION ENDS */}

			{/* TESTIMONIAL SECTION STARTS */}
			<Testimonials />
			{/* TESTIMONIAL SECTION ENDS */}

			{/* FOOTER SECTION STARTS */}
			<Footer />
			{/* FOOTER SECTION ENDS */}
		</motion.div>
	);
};

export default Home;
