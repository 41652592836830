import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import client from "../client";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import BlockContent from "@sanity/block-content-to-react";
import { textVariant } from "../utils/motion";
import { RiExternalLinkLine } from "react-icons/ri";

const SingleEvent = () => {
	const [singlePost, setSinglePost] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { slug } = useParams();

	useEffect(() => {
		client
			.fetch(
				`*[slug.current == "${slug}"]{
      title,
      slug,
      description,
		day,
		month,
		organizer,
		timeDay,
		details,
		locationDate,
		content,
		link,
      image{
        asset->{
          _id,
          url
        },
		  alt
      },
      flyerImage{
        asset->{
          _id,
          url
        },
		  alt
      }
    }`
			)
			.then((data) => {
				console.log(data[0]);
				setSinglePost(data[0]);
				setIsLoading(false);
			})
			.catch(console.error);
	}, [slug]);

	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "spring", ease: "easeIn", duration: 1 }}
		>
			{isLoading ? (
				<h1>Loading...</h1>
			) : (
				<div className="main__container">
					<div className="overflow-hidden relative mt-16 lg:mt-26 max-w-[1200px] mx-auto">
						<img
							src={singlePost.flyerImage.asset.url}
							alt={singlePost.title}
							className="lg:h-[70vh] w-full object-cover"
						/>
					</div>

					<div className="main__container grid gap-16 lg:grid-cols-3">
						<div className="w-full lg:col-span-2">
							<h1 className="font-semibold text-3xl lg:text-5xl mt-4">
								{singlePost.title}
							</h1>

							<motion.div
								variants={textVariant(0.6)}
								className="mt-2 flex items-center gap-2"
							>
								<Link to="/events" className="text-[#00715D] font-medium">
									Events{" "}
								</Link>
								<div className="text-black/40">/</div>
								<span className="text-black/40 block font-medium">
									{" "}
									{singlePost.slug.current.slice(0, 18)}
								</span>
							</motion.div>

							<div className="block__content mt-12">
								<BlockContent
									blocks={singlePost.content}
									projectId="hhcrzksh"
									dataset="production"
								/>
							</div>

							<Link
								to={singlePost.link}
								target="_blank"
								className="mt-10 inline-flex  gap-2 text-white hover:bg-[#005546] font-semibold bg-[#00715D] px-6 py-3 "
							>
								Purchase tickets now <RiExternalLinkLine size={25} />
							</Link>
						</div>
						<div className="lg:mt-36">
							<aside className="w-full">
								<div className="w-full border-2 border-[#f2f2f2] bg-[#f4f6f8] py-12 px-8 text-[#44464c]">
									<h2 className="text-[1.1rem] font-semibold mb-8">
										Your contribution goes a long way to changing a kid's life,
										please consider donating today.
									</h2>
									<Link
										to="/donate"
										className="bg-[#00715D] inline-flex text-white font-semibold py-[15px] px-[20px]"
									>
										Donate Now
									</Link>
								</div>
							</aside>
							<aside className="w-full mt-10">
								<div className="w-full border-2 border-[#f2f2f2] bg-[#f4f6f8] py-12 px-8 text-[#44464c]">
									<h2 className="text-[1.1rem] font-semibold mb-8">
										Be part of a kid's life today by becoming a volunteer.
										You're needed.
									</h2>
									<Link
										to="/contact"
										className="bg-[#00715D] inline-flex text-white font-semibold py-[15px] px-[20px]"
									>
										Volunteer Today
									</Link>
								</div>
							</aside>
						</div>
					</div>
				</div>
			)}
			<Testimonials />
			<Footer />
		</motion.div>
	);
};

export default SingleEvent;
