import React from "react";
import { Link } from "react-router-dom";
import WhatWeDoImg from "../images/what-we-do-img.png";
import Services from "../components/Services";
import { services } from "../data";
import Volunteer from "../components/Volunteer";
import EventComp from "../components/EventComp";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../utils/motion";
import VideoComp from "../components/Video";

const WhatWeDo = () => {
	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "spring", ease: "easeIn", duration: 1 }}
		>
			<div className="overflow-hidden relative">
				<img
					src={WhatWeDoImg}
					className="h-[50vh] md:h-auto xl:h-[60vh] xl:w-full w-auto object-cover"
					alt="Kids 1st International"
				/>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="mt-10 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4"
				>
					<motion.h1
						variants={textVariant(0.5)}
						className="text-[2rem] text-center lg:text-5xl xl:text-6xl text-white font-semibold uppercase"
					>
						What We Do
					</motion.h1>
					<motion.div
						variants={textVariant(0.6)}
						className="mt-2 flex justify-center items-center gap-2"
					>
						<Link to="/" className="text-[#FBC109]">
							Home{" "}
						</Link>
						<span className="text-white/40 block"> {"// What We Do"}</span>
					</motion.div>
				</motion.div>
			</div>

			<Services
				title="How We Help Kids Around The World"
				titleColor="text-[#263734]"
				subTitleColor="text-[#D9A503]"
				bgColor="bg-[#F9F5E8]"
				servicesData={services}
				showAll={true}
				showLink={false}
				mt="mt-0"
				lgmt="lg:mt-0"
			/>

			{/* GREEN SUB-SECTION STARTS */}
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
			>
				<motion.div
					variants={textVariant(0)}
					className="my-20 lg:my-32 bg-[#00715D] px-[1.4rem] pt-6 pb-12 main__container lg:flex lg:justify-between lg:items-center lg:p-12"
				>
					<h2 className="text-3xl lg:text-4xl xl:text-5xl text-white font-semibold lg:max-w-[40%]">
						Let’s Help Out With Your Charity
					</h2>
					<div className="mt-8 lg:mt-[unset]">
						<Link
							to="/donate"
							className="bg-[#FDD65B] text-black font-semibold py-[15px] px-[20px] mr-4"
						>
							Donate Now
						</Link>
						<Link
							to="/contact-us"
							className="bg-[#FDD65B] text-black font-semibold py-[15px] px-[20px] mr-4"
						>
							Volunteer
						</Link>
					</div>
				</motion.div>
			</motion.div>
			{/* GREEN SUB-SECTION ENDS */}

			<VideoComp />

			<Volunteer />

			<EventComp />

			<Testimonials />

			<Footer />
		</motion.div>
	);
};

export default WhatWeDo;
