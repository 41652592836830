import React from "react";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import GetInvolvedImg from "../images/get-involved-hero.png";
import SponsorChild from "../images/sponsor-child.png";
import SponsorSchool from "../images/sponsor-school.png";
import GiveDonation from "../images/give-donation.png";
import Volunteer from "../images/volunteer.png";
import CorporateSupport from "../images/corporate-support.png";
import { motion } from "framer-motion";
import { navVariants, staggerContainer, textVariant } from "../utils/motion";

const GetInvolved = () => {
	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "spring", ease: "easeIn", duration: 1 }}
		>
			<div className="overflow-hidden relative">
				<img
					src={GetInvolvedImg}
					className="h-[50vh] md:h-auto xl:h-[60vh] xl:w-full w-auto object-cover"
					alt="Kids 1st International"
				/>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="mt-10 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4"
				>
					<motion.h1
						variants={textVariant(0.5)}
						className="text-[2rem] text-center lg:text-5xl xl:text-6xl text-white font-semibold uppercase"
					>
						Get Involved
					</motion.h1>
					<motion.div
						variants={textVariant(0.6)}
						className="mt-2 flex justify-center items-center gap-2"
					>
						<Link to="/" className="text-[#FBC109]">
							Home{" "}
						</Link>
						<span className="text-white/40 block"> {"// Get-Involved"}</span>
					</motion.div>
				</motion.div>
			</div>

			<div className="mt-20 lg:mt-32">
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0 }}
					className="main__container"
				>
					<motion.div variants={navVariants}>
						<span className="uppercase tracking-widest text-sm text-[#D9A503] block text-center font-semibold mb-2">
							Get Involved
						</span>

						<h2 className="text-3xl lg:text-4xl lg:w-[400px] mx-auto text-[#263734] text-center font-semibold mb-5">
							Change A Child’s Life For Good
						</h2>
					</motion.div>
					<motion.div
						variants={staggerContainer}
						initial="hidden"
						whileInView="show"
						viewport={{ once: true, amount: 0.1 }}
					>
						<motion.div
							variants={textVariant(0)}
							className="mt-12 lg:flex lg:gap-12 lg:items-center"
						>
							<img
								src={SponsorChild}
								alt="Kids 1st International"
								className="w-full lg:w-[50%]"
							/>
							<div className="mt-6">
								<h3 className="text-3xl lg:text-4xl text-[#263734] font-semibold mb-3 lg:mb-8">
									Sponsor a Child
								</h3>
								<p className="lg:text-lg">
									One of the most powerful things you can do is take a child
									under your wings and encourage, motivate and empower them to
									fly on their own. <br />
									<br /> Make the sacrifice and enrich your own life.
								</p>
							</div>
						</motion.div>
						<motion.div
							variants={textVariant(0)}
							className="mt-12 lg:flex lg:gap-12 lg:items-center"
						>
							<img
								src={SponsorSchool}
								alt="Kids 1st International"
								className="w-full lg:w-[50%] lg:order-1"
							/>
							<div className="mt-6">
								<h3 className="text-3xl lg:text-4xl text-[#263734] font-semibold mb-3 lg:mb-8">
									Sponsor a School
								</h3>
								<p className="lg:text-lg">
									The smallest items that may seem inconsequential to most, mean
									everything to children who are starving to be educated. Give
									pencils, paper, books. <br />
									<br /> Contribute towards chairs, desks and blackboards and
									you too will learn the value of sponsorship.
								</p>
							</div>
						</motion.div>
						<motion.div
							variants={textVariant(0)}
							className="mt-12 lg:flex lg:gap-12 lg:items-center"
						>
							<img
								src={GiveDonation}
								alt="Kids 1st International"
								className="w-full lg:w-[50%]"
							/>
							<div className="mt-6">
								<h3 className="text-3xl lg:text-4xl text-[#263734] font-semibold mb-3 lg:mb-8">
									Give a Donation
								</h3>
								<p className="lg:text-lg">
									Every little bit helps. Nothing is turned away and everything
									- no matter how small it may be - is appreciated.
								</p>
								<Link
									to="/donate"
									className="bg-[#FDD65B] inline-flex text-black mt-8 font-semibold py-[15px] px-[20px] mr-4"
								>
									Donate Now
								</Link>
							</div>
						</motion.div>
						<motion.div
							variants={0}
							className="mt-12 lg:flex lg:gap-12 lg:items-center"
						>
							<img
								src={Volunteer}
								alt="Kids 1st International"
								className="w-full lg:w-[50%] lg:order-1"
							/>
							<div className="mt-6">
								<h3 className="text-3xl lg:text-4xl text-[#263734] font-semibold mb-3 lg:mb-8">
									Volunteer
								</h3>
								<p className="lg:text-lg">
									People who are willing to give time and effort to the cause of
									empowering children are always welcome to assist <br />
									<br />
									Let us know how you'd like to contribute and we will have
									meaningful and satisfying work for you to accomplish.
								</p>
								<Link
									to="/contact-us"
									className="bg-[#FDD65B] inline-flex text-black mt-8 font-semibold py-[15px] px-[20px] mr-4"
								>
									Volunteer Now
								</Link>
							</div>
						</motion.div>
						<motion.div
							variants={textVariant(0)}
							className="mt-12 lg:flex lg:gap-12 lg:items-center"
						>
							<img
								src={CorporateSupport}
								alt="Kids 1st International"
								className="w-full lg:w-[50%]"
							/>
							<div className="mt-6">
								<h3 className="text-3xl lg:text-4xl text-[#263734] font-semibold mb-3 lg:mb-8">
									Corporate Support
								</h3>
								<p className="lg:text-lg">
									Your company's tax deductible contributions are appreciated
									and your organization will strengthen its moral standing
									within your community.
								</p>
							</div>
						</motion.div>
					</motion.div>
				</motion.div>
			</div>
			<Testimonials />
			<Footer />
		</motion.div>
	);
};

export default GetInvolved;
