export const navVariants = {
	hidden: {
		opacity: 0,
		y: -50,
		transition: {
			type: "spring",
			stiffness: 300,
			damping: 140,
		},
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			type: "spring",
			stiffness: 80,
			delay: 0.2,
		},
	},
};

export const slideIn = (direction, type, delay, duration) => ({
	hidden: {
		x: direction === "left" ? "-100%" : direction === "right" ? "100%" : 0,
		y: direction === "up" ? "100%" : direction === "down" ? "100%" : 0,
	},
	show: {
		x: 0,
		y: 0,
		transition: {
			type,
			delay,
			duration,
			ease: "easeOut",
		},
	},
});

export const staggerContainer = (staggerChildren, delayChildren) => ({
	hidden: {},
	show: {
		transition: {
			staggerChildren,
			delayChildren,
		},
	},
});

export const textVariant = (delay) => ({
	hidden: {
		y: 50,
		opacity: 0,
	},
	show: {
		y: 0,
		opacity: 1,
		transition: {
			type: "spring",
			duration: 1.25,
			delay,
		},
	},
});

export const leftVariant = (delay) => ({
	hidden: {
		x: -50,
		opacity: 0,
	},
	show: {
		x: 0,
		opacity: 1,
		transition: {
			type: "spring",
			duration: 1.25,
			delay,
		},
	},
});

export const rightVariant = (delay) => ({
	hidden: {
		x: 50,
		opacity: 0,
	},
	show: {
		x: 0,
		opacity: 1,
		transition: {
			type: "spring",
			duration: 1.25,
			delay,
		},
	},
});

export const opacityVariant = (delay) => ({
	hidden: {
		opacity: 0,
	},
	show: {
		opacity: 1,
		transition: {
			type: "spring",
			duration: 1.25,
			delay,
		},
	},
});

export const fadeUpVariant = (delay) => ({
	hidden: {
		opacity: 0,
		y: 60,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			type: "spring",
			duration: 0.8,
			delay,
		},
	},
});

export const fadeDownVariant = (delay) => ({
	hidden: {
		opacity: 0,
		y: -60,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			type: "spring",
			duration: 1.25,
			delay,
		},
	},
});

export const textContainer = {
	hidden: {
		opacity: 0,
	},
	show: (i = 1) => ({
		opacity: 1,
		transition: { staggerChildren: 0.1, delayChildren: i * 0.1 },
	}),
};

export const textVariant2 = {
	hidden: {
		opacity: 0,
		y: 20,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			type: "tween",
			ease: "easeIn",
		},
	},
};

export const fadeIn = (direction, type, delay, duration) => ({
	hidden: {
		x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
		y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
		opacity: 0,
	},
	show: {
		x: 0,
		y: 0,
		opacity: 1,
		transition: {
			type,
			delay,
			duration,
			ease: "easeOut",
		},
	},
});

export const planetVariants = (direction) => ({
	hidden: {
		x: direction === "left" ? "-100%" : "100%",
		rotate: 120,
	},
	show: {
		x: 0,
		rotate: 0,
		transition: {
			type: "spring",
			duration: 1.8,
			delay: 0.5,
		},
	},
});

export const zoomIn = (delay, duration) => ({
	hidden: {
		scale: 0,
		opacity: 0,
	},
	show: {
		scale: 1,
		opacity: 1,
		transition: {
			type: "tween",
			delay,
			duration,
			ease: "easeOut",
		},
	},
});

export const footerVariants = {
	hidden: {
		opacity: 0,
		y: 50,
		transition: {
			type: "spring",
			stiffness: 300,
			damping: 140,
		},
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			type: "spring",
			stiffness: 80,
			delay: 0.5,
		},
	},
};

const easing = [0.6, -0.05, 0.01, 0.99];

export const fadeInUp = {
	initial: {
		y: 60,
		opacity: 0,
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			ease: easing,
		},
	},
};

export const stagger = {
	animate: {
		transition: {
			staggerChildren: 0.5,
		},
	},
};

export const cardVariants = {
	offscreen: {
		opacity: 0,
		y: 60,
	},
	onscreen: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.6,
			ease: easing,
		},
	},
};

export const fadeDown = {
	offscreen: {
		opacity: 0,
		y: -60,
	},
	onscreen: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.6,
			ease: easing,
		},
	},
};

export const fadeUp = {
	offscreen: {
		opacity: 0,
		y: 60,
	},
	onscreen: {
		opacity: 1,
		y: 0,
		transition: {
			duration: 0.6,
			ease: easing,
		},
	},
};
