import ScrollToTop from "./components/ScrollToTop";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import WhatWeDo from "./pages/WhatWeDo";
import Events from "./pages/Events";
import GetInvolved from "./pages/GetInvolved";
import Contact from "./pages/Contact";
import Donate from "./pages/Donate";

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
import BackgroundAudio from "./components/BackgroundAudio";
import SingleEvent from "./pages/SingleEvent";

function App() {
	return (
		<div className="overflow-hidden">
			<BackgroundAudio />
			<Navbar />
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/about-us" element={<About />} />
				<Route path="/what-we-do" element={<WhatWeDo />} />
				<Route path="/events/:slug" element={<SingleEvent />} />
				<Route path="/events" element={<Events />} />
				<Route path="/get-involved" element={<GetInvolved />} />
				<Route path="/contact-us" element={<Contact />} />
				<Route path="/donate" element={<Donate />} />
			</Routes>
		</div>
	);
}
register();
export default App;
