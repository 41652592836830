import React from "react";
import { RiFacebookFill, RiLinkedinFill, RiTwitterFill } from "react-icons/ri";
import { TbHeartHandshake } from "react-icons/tb";
import { Link } from "react-router-dom";
import { volunteer } from "../data";
import { motion } from "framer-motion";
import { fadeUpVariant, staggerContainer } from "../utils/motion";

const Volunteer = () => {
	return (
		<motion.div
			variants={staggerContainer}
			initial="hidden"
			whileInView="show"
			viewport={{ once: true, amount: 0.25 }}
			className="bg-[#F9F5E8] py-20 lg:py-32"
		>
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="main__container"
			>
				<motion.span
					variants={fadeUpVariant(0.2)}
					className="uppercase tracking-widest text-sm text-center text-[#D9A503] block font-semibold mb-2"
				>
					Get Involved
				</motion.span>

				<motion.h2
					variants={fadeUpVariant(0.2)}
					className="text-3xl lg:text-4xl lg:w-[500px] mx-auto text-[#263734] text-center font-semibold mb-8 capitalize"
				>
					Join Us in Making a Difference in a Child’s life
				</motion.h2>

				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="md:grid md:grid-cols-2 md:gap-6 lg:grid-cols-4"
				>
					{volunteer.map(({ image, name, fb, lin, tw }, index) => (
						<motion.div
							variants={fadeUpVariant(0.2)}
							className="get__involved-article mb-8"
						>
							<img
								src={image}
								className="object-cover h-[336px] w-full"
								alt="Kids 1st International"
							/>
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									{name}
								</h3>
								<div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to={fb}
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to={lin}
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiLinkedinFill className="text-white" />
									</Link>
									<Link
										to={tw}
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiTwitterFill className="text-white" />
									</Link>
								</div>
							</div>
						</motion.div>
					))}

					<motion.div
						variants={fadeUpVariant(0.2)}
						className="get__involved-btn lg:mt-[unset] bg-[#00715D] flex justify-center items-center flex-col py-12 px-8"
					>
						<div className="p-4 bg-white rounded-full flex items-center justify-center">
							<TbHeartHandshake size={70} className="text-[#00715D]" />
						</div>
						<h3 className="text-white text-3xl text-center font-semibold mt-5 mb-5">
							Become a Volunteer
						</h3>
						<p className="text-white font-light text-center">
							People willing to give time and effort to the cause are more than
							welcome.
						</p>
						<Link
							to="/contact-us"
							className="mt-6 inline-flex bg-white text-[#00715D] rounded-full font-semibold py-[20px] px-[40px] mr-4"
						>
							Join Us Today
						</Link>
					</motion.div>
				</motion.div>
			</motion.div>
		</motion.div>
	);
};

export default Volunteer;
