import React, { useRef, useState, useEffect } from "react";
import AreYouListening from "../audio/Are-You-Listening-By-Kirk-Franklin.mp3";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BackgroundAudio = () => {
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	// const [toastShown, setToastShown] = useState(false);

	const notify = () => {
		toast("We Do Not Have The License To This Song");
	};

	const toggleAudio = () => {
		const audio = audioRef.current;
		if (audio.paused) {
			audio
				.play()
				.then(() => {
					setIsPlaying(true);
					notify();
				})
				.catch((error) => {
					console.log("Failed to play audio:", error);
				});
		} else {
			audio.pause();
			setIsPlaying(false);
		}
	};

	useEffect(() => {
		const audio = audioRef.current;

		const playPromise = audio.play();
		if (playPromise !== undefined) {
			playPromise
				.then(() => {
					setIsPlaying(true);
				})
				.catch((error) => {
					console.log("Failed to play audio:", error);
				});
		}

		return () => {
			audio.pause();
		};
	}, []);

	return (
		<div>
			<audio ref={audioRef} src={AreYouListening} loop autoPlay={false} />
			<button
				onClick={toggleAudio}
				className="fixed bottom-8 right-6 md:bottom-10 md:right-8 lg:bottom-12 lg:right-12 flex justify-center items-center p-4 bg-[#364E4A]/70 hover:bg-[#364E4A] rounded-full z-[999]"
			>
				{isPlaying ? (
					<BsPauseFill size={30} className="text-white" />
				) : (
					<BsPlayFill size={30} className="text-white" />
				)}
			</button>
			<ToastContainer position="bottom-center" />
		</div>
	);
};

export default BackgroundAudio;
