import React from "react";
import WhiteLogo from "../images/Kids1st-logo-white.png";
import {
	RiFacebookFill,
	RiInstagramFill,
	// RiLinkedinFill,
	// RiYoutubeFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../utils/motion";

const Footer = () => {
	return (
		<motion.div
			variants={staggerContainer}
			initial="hidden"
			whileInView="show"
			viewport={{ once: true, amount: 0.25 }}
			className="bg-[#263734] pt-20 pb-8 lg:pt-32"
		>
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="main__container"
			>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="xl:flex xl:items-start"
				>
					<motion.div variants={textVariant(0.2)} className="xl:flex-[25%]">
						<img
							src={WhiteLogo}
							alt="Kids 1st International Logo"
							className="w-[200px]"
						/>
						<p className="font-light text-white mt-6 mb-8">
							No one knows what an equitable world looks like. we are working
							together with only one hope for raising a better tomorrow.
						</p>
						<div className="social__buttons flex items-center gap-3 rounded-full">
							<Link
								to="http://www.facebook.com/kids1stinc"
								target="_blank"
								className="p-2 bg-[#364E4A]"
							>
								<RiFacebookFill
									size={30}
									className="text-white/60 hover:text-white"
								/>
							</Link>
							<Link
								to="https://www.instagram.com/kids1stinternational/"
								target="_blank"
								className="p-2 flex justify-center items-center bg-[#364E4A]"
							>
								<RiInstagramFill
									size={30}
									className="text-white/60 hover:text-white"
								/>
							</Link>
							{/* <Link
								to="https://www.linkedin.com"
								target="_blank"
								className="p-2 flex justify-center items-center bg-[#364E4A]"
							>
								<RiLinkedinFill
									size={30}
									className="text-white/60 hover:text-white"
								/>
							</Link>
							<Link
								to="https://www.youtube.com"
								target="_blank"
								className="p-2 flex justify-center items-center bg-[#364E4A]"
							>
								<RiYoutubeFill
									size={30}
									className="text-white/60 hover:text-white"
								/>
							</Link> */}
						</div>
					</motion.div>
					<motion.div
						variants={textVariant(0.4)}
						className="bg-[#364E4A] mt-20 xl:mt-[unset] py-14 px-6 lg:px-12 flex flex-wrap xl:flex-nowrap xl:gap-10 gap-16 items-start xl:flex-[75%]"
					>
						<div className="xl:w-[50%]">
							<h3 className="text-lg font-semibold text-white">User Link</h3>

							<ul className="list-none flex flex-col gap-2 mt-4">
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/about-us">About Us</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/what-we-do">What We Do</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/events">Events</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/get-involved">Get Involved</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/contact-us">Contact Us</Link>
								</li>
							</ul>
						</div>

						<div className="xl:w-[50%]">
							<h3 className="text-lg font-semibold text-white">Service Us</h3>

							<ul className="list-none flex flex-col gap-2 mt-4">
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/donate">Donate</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/contact-us">Sponsor</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/contact-us">Fundraise</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/contact-us">Volunteer</Link>
								</li>
								<li className="font-light text-white/90 hover:text-[#FDD65B] hover:font-medium">
									<Link to="/contact-us">Partner</Link>
								</li>
							</ul>
						</div>
						<div className="xl:w-full">
							<h3 className="text-lg font-semibold text-white">Contacts</h3>
							<p className="font-light text-white/90 mt-4 mb-4">
								North America: 147 W Orange St Brentwood, NY 11717
							</p>
							<Link
								to="mailto:kidsfirst06@hotmail.com"
								className="mb-4 font-light text-white/90 block"
							>
								kidsfirst06@hotmail.com
							</Link>
							<Link
								to="tel:1-631-782-8985"
								className="font-light text-white/90"
							>
								1-631-782-8985
							</Link>
						</div>
						<div className="xl:w-full">
							<h3 className="text-lg font-semibold text-white">Donate</h3>
							<p className="font-light text-white/90 mt-4 mb-8">
								Help us change the lives of children in the world.{" "}
							</p>
							<Link
								to="/donate"
								className="bg-[#FDD65B] inline-flex text-black font-semibold py-[15px] px-[50px]"
							>
								Donate Now
							</Link>
						</div>
					</motion.div>
				</motion.div>
			</motion.div>
			<div className="bg-[#364E4A] my-10 w-full h-[1px]" />
			<p className="text-center text-white/70 text-sm">
				Copyright © 2023 Kids1st International. All Rights Reserved
			</p>
		</motion.div>
	);
};

export default Footer;
