import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { navVariants, staggerContainer } from "../utils/motion";

const Services = ({
	servicesData,
	showAll,
	showLink,
	title,
	bgColor,
	titleColor,
	subTitleColor,
	mt,
	lgmt,
}) => {
	const itemsToShow = showAll ? servicesData : servicesData.slice(0, 3);

	return (
		<motion.div
			variants={staggerContainer}
			initial="hidden"
			whileInView="show"
			viewport={{ once: true, amount: 0.25 }}
			className={`${mt} ${lgmt} py-12 md:py-16 lg:py-32 ${bgColor}`}
		>
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="main__container"
			>
				<motion.span
					variants={navVariants}
					className={`uppercase tracking-widest text-sm text-center ${subTitleColor} block font-semibold mb-2`}
				>
					Services
				</motion.span>

				<motion.h2
					variants={navVariants}
					className={`text-3xl lg:text-4xl ${titleColor} text-center font-semibold mb-8 capitalize md:mx-auto md:w-[500px]`}
				>
					{title}
				</motion.h2>

				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="md:grid md:grid-cols-2 md:gap-6 lg:grid-cols-3"
				>
					{itemsToShow.map(({ image, title, desc }, index) => (
						<motion.div
							variants={navVariants}
							className="flex flex-col mb-6 lg:mb-0"
							key={index}
						>
							<div className="w-full overflow-hidden">
								<img src={image} alt="Kids 1st Internation" />
							</div>
							<div className="pt-8 px-6 pb-12 bg-white">
								<h3 className="text-[1.2rem] font-semibold text-[#263734]">
									{title}
								</h3>
								<p className="text-[#747474] mt-4">{desc}</p>
							</div>
						</motion.div>
					))}
				</motion.div>
				{showLink && (
					<motion.div
						variants={navVariants}
						className="flex justify-center items-center lg:mt-8 xl:mt-14"
					>
						<Link
							to="/what-we-do"
							className="bg-[#FDD65B] inline-flex text-black font-semibold py-[15px] px-[70px]"
						>
							See All
						</Link>
					</motion.div>
				)}
			</motion.div>
		</motion.div>
	);
};

export default Services;
