import React from "react";
import Video from "../images/kids-1st-vid.mp4";

const VideoComp = () => {
	return (
		<div className="mx-auto lg:mx-auto md:mx-auto sm:mx-auto">
			<div className="flex flex-col lg:flex-row lg:gap-0 items-center justify-center gap-12 sm:gap-8 w-full mx-6 mb-20">
				<div className="w-[350px] ml-24 mr-36 lg:w-[350px] md:w-[500px]">
					<span className="uppercase tracking-widest text-sm text-[#D9A503] block font-semibold mb-2">
						Project
					</span>
					<h2 className="text-3xl lg:text-4xl lg:w-[500px] mx-auto text-[#263734] font-semibold mb-8 capitalize">
						Here is part of the projects we've done to help kids all over the
						world
					</h2>
					<p className="font-light">
						Let's discuss a recent project where our Vice President and popular
						Nollywood actor <strong>Ikechukwu Mitchel Ogbonna</strong>{" "}
						collaborated to make a difference. They came together to support
						children by providing essential school kits and awarding
						scholarships to the most outstanding students. This initiative aimed
						not only to equip the kids with necessary tools for their education
						but also to inspire them for a brighter future. The event left a
						lasting impact, fostering a sense of community care and motivating
						the students to excel.
					</p>
				</div>
				<div className="w-[350px] ml-24 mr-36 lg:w-[400px] md:w-[500px]">
					<video src={Video} controls className=""></video>
				</div>
			</div>
		</div>
	);
};

export default VideoComp;
