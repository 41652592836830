import React from "react";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import ContactImg from "../images/contact-hero-img.png";
import { Link } from "react-router-dom";
import { RiMailLine, RiMapPinLine, RiPhoneLine } from "react-icons/ri";
import { motion } from "framer-motion";
import {
	navVariants,
	opacityVariant,
	staggerContainer,
	textVariant,
} from "../utils/motion";

const Contact = () => {
	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "spring", ease: "easeIn", duration: 1 }}
		>
			<div className="overflow-hidden relative">
				<img
					src={ContactImg}
					className="h-[50vh] md:h-auto xl:h-[60vh] xl:w-full w-auto object-cover"
					alt="Kids 1st International"
				/>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="mt-10 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4"
				>
					<motion.h1
						variants={textVariant(0.5)}
						className="text-[2rem] text-center lg:text-5xl xl:text-6xl text-white font-semibold uppercase"
					>
						Contact Us
					</motion.h1>
					<motion.div
						variants={textVariant(0.6)}
						className="mt-2 flex justify-center items-center gap-2"
					>
						<Link to="/" className="text-[#FBC109]">
							Home{" "}
						</Link>
						<span className="text-white/40 block"> {"// Contact-Us"}</span>
					</motion.div>
				</motion.div>
			</div>
			<div className="mt-20 lg:mt-32">
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="main__container"
				>
					<motion.div variants={navVariants}>
						<span className="uppercase tracking-widest text-sm text-[#D9A503] block text-center font-semibold mb-2">
							Contact Us
						</span>

						<h2 className="text-3xl lg:text-4xl lg:w-[400px] mx-auto text-[#263734] text-center font-semibold mb-5">
							You Can Reach Us Easily Here.
						</h2>
					</motion.div>

					<motion.div
						variants={textVariant(0)}
						className="mt-12 flex gap-8 flex-col md:grid md:grid-cols-2 lg:grid-cols-3"
					>
						<div className="flex flex-col justify-center items-center p-10 border border-[#00715D]">
							<div className="flex justify-center items-center bg-[#00715d] p-4 rounded-full">
								<RiMapPinLine size={40} className="text-white" />
							</div>
							<p className="mt-4 text-2xl font-semibold text-[#00715d] text-center">
								Address
							</p>
							<span className="block text-lg text-center text-[#7D7D7D] mt-2">
								North America: 147 W Orange St Brentwood, NY 11717
							</span>
						</div>
						<div className="flex flex-col justify-center items-center p-10 border border-[#00715D]">
							<div className="flex justify-center items-center bg-[#00715d] p-4 rounded-full">
								<RiPhoneLine size={40} className="text-white" />
							</div>
							<p className="mt-4 text-2xl font-semibold text-[#00715d] text-center">
								Phone Number
							</p>
							<a
								href="tel:1-631-782-8985"
								className="block text-lg text-center text-[#7D7D7D] mt-2"
							>
								1-631-782-8985
							</a>
						</div>
						<div className="flex flex-col justify-center items-center p-10 border border-[#00715D]">
							<div className="flex justify-center items-center bg-[#00715d] p-4 rounded-full">
								<RiMailLine size={40} className="text-white" />
							</div>
							<p className="mt-4 text-2xl font-semibold text-[#00715d] text-center">
								Email Address
							</p>
							<a
								href="mailto:kidsfirst06@hotmail.com"
								className="block text-lg text-center text-[#7D7D7D] mt-2"
							>
								kidsfirst06@hotmail.com
							</a>
						</div>
					</motion.div>

					<motion.div variants={navVariants}>
						<h2 className="text-3xl lg:text-4xl lg:w-[400px] mx-auto text-[#263734] text-center font-semibold mt-20 lg:mt-32">
							You Can Also Write To Us From Here.
						</h2>

						<motion.div variants={textVariant(0)}>
							<form action="" className="mt-8 lg:mt-16">
								<div className="mb-4 lg:mb-6 md:flex md:items-center gap-4 lg:gap-6">
									<input
										type="text"
										name="name"
										id="name"
										placeholder="Your Name"
										className="p-4 lg:p-6 bg-[#F2F2F2] w-full mb-4 md:mb-0 font-semibold outline-none border-none"
									/>
									<input
										type="email"
										name="email"
										id="email"
										placeholder="Your Email"
										className="p-4 lg:p-6 bg-[#F2F2F2] w-full font-semibold outline-none border-none"
									/>
								</div>
								<div className="mb-4 lg:mb-6 md:flex md:items-center gap-4 lg:gap-6">
									<input
										type="text"
										name="subject"
										id="subject"
										placeholder="Subject"
										className="p-4 lg:p-6 bg-[#F2F2F2] w-full mb-4 md:mb-0 font-semibold outline-none border-none"
									/>
									<input
										type="tel"
										name="tel"
										id="tel"
										placeholder="Phone Number"
										className="p-4 lg:p-6 bg-[#F2F2F2] w-full font-semibold outline-none border-none"
									/>
								</div>
								<textarea
									name="message"
									id="message"
									cols="30"
									rows="15"
									placeholder="Enter your message..."
									className="p-4 lg:p-6 bg-[#F2F2F2] w-full mb-4 md:mb-0 font-semibold outline-none border-none"
								></textarea>
								<button
									type="submit"
									className="bg-[#FDD65B] inline-flex text-black mt-4 lg:mt-10 font-semibold py-[15px] lg:py-[20px] px-[20px] lg:px-[50px] mr-4"
								>
									Submit Message
								</button>
							</form>
						</motion.div>

						<motion.div variants={opacityVariant(0)}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.0774578381265!2d-73.21457892407527!3d40.760321071386414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e833d8b4101b73%3A0x7454e1f114d98b37!2s147%20W%20Orange%20St%2C%20Brentwood%2C%20NY%2011717%2C%20USA!5e0!3m2!1sen!2sng!4v1684414834680!5m2!1sen!2sng"
								// width="600"
								// height="450"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title="kids 1st International"
								className="mt-20 lg:mt-32 w-full h-[450px] lg:h-[600px]"
							></iframe>
						</motion.div>
					</motion.div>
				</motion.div>
			</div>
			<Testimonials />
			<Footer />
		</motion.div>
	);
};

export default Contact;
