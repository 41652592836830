import React, { useEffect, useState } from "react";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { BiTime } from "react-icons/bi";
import { MdOutlinePinDrop } from "react-icons/md";
import { Link } from "react-router-dom";
import EventImage from "../images/events-img.png";
import { motion } from "framer-motion";
import { staggerContainer, textVariant } from "../utils/motion";
import client from "../client";

const Events = () => {
	const [eventData, setEventData] = useState([]);

	useEffect(() => {
		client
			.fetch(
				`*[_type == "event"]{
      title,
      slug,
      description,
		day,
		month,
		organizer,
		timeDay,
		details,
		locationDate,
		content,
      image{
        asset->{
          _id,
          url
        },
		  alt
      }
    }`
			)
			.then((data) => {
				console.log(data);
				setEventData(data);
			})
			.catch(console.error);
	}, []);

	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "spring", ease: "easeIn", duration: 1 }}
		>
			<div className="overflow-hidden relative">
				<img
					src={EventImage}
					className="h-[50vh] md:h-auto xl:h-[60vh] xl:w-full w-auto object-cover"
					alt="Kids 1st International"
				/>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="mt-10 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4"
				>
					<motion.h1
						variants={textVariant(0.5)}
						className="text-[2rem] text-center lg:text-5xl xl:text-6xl text-white font-semibold uppercase"
					>
						Events
					</motion.h1>
					<motion.div
						variants={textVariant(0.6)}
						className="mt-2 flex justify-center items-center gap-2"
					>
						<Link to="/" className="text-[#FBC109]">
							Home{" "}
						</Link>
						<span className="text-white/40 block"> {"// Events"}</span>
					</motion.div>
				</motion.div>
			</div>
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="mt-20 lg:mt-32"
			>
				<motion.div variants={textVariant(0)} className="main__container">
					<div className="">
						<div>
							<span className="uppercase tracking-widest text-sm text-[#D9A503] block text-center font-semibold mb-2">
								Our Events
							</span>

							<h2 className="text-3xl lg:text-4xl lg:w-[400px] mx-auto text-[#263734] text-center font-semibold mb-5">
								See Upcoming Events and Webinars
							</h2>
						</div>
					</div>

					<div className="mt-10 lg:mt-14 lg:grid lg:grid-cols-2 lg:gap-16">
						{/* {eventData.map((item) => (
							<p key={item.slug.current}>{item.title}</p>
						))} */}

						{eventData.length ? (
							eventData.map((item) => (
								<div key={item.slug.current} className="mb-10 lg:mb-[unset]">
									<div className="img relative overflow-hidden">
										<Link to={`/events/${item.slug.current}`}>
											<img src={item.image.asset.url} alt={item.title} />
										</Link>
										<div className="flex flex-col items-center justify-center p-4 bg-[#E91E63] absolute top-0 left-0">
											<span className="block text-center text-white text-4xl font-bold">
												{item.day}
											</span>
											<span className="block text-center text-white text-[1.2rem] font-medium">
												{item.month}
											</span>
										</div>
									</div>
									<div className="info mt-4 flex flex-wrap items-center gap-2">
										<p className="text-[#747474] font-medium">
											Organized By:{" "}
											<span className="text-[#E91E63]">{item.organizer}</span>
										</p>
										<p className="text-[#747474] flex justify-center items-center gap-2 font-medium uppercase">
											<BiTime size={20} className="text-[#E91E63]" />{" "}
											{item.timeDay}
										</p>
										<div className="w-full h-[1px] bg-[#747474] mt-4"></div>
									</div>

									<div className="details mt-4">
										<h3 className="event__title text-2xl text-[#263734] font-semibold mb-4">
											{item.title}
										</h3>
										<p className="event__details text-[#747474]">
											{item.details}
										</p>
										<div className="venue__date bg-[#F0F0F0] mt-4 p-3 inline-block">
											<p className="flex items-center gap-2 font-semibold">
												<MdOutlinePinDrop
													size={20}
													className="text-[#E91E63]"
												/>{" "}
												{item.locationDate}
											</p>
										</div>
										{/* <div>
											<BlockContent
												blocks={item.content}
												projectId="hhcrzksh"
												dataset="production"
											/>
										</div> */}
									</div>
								</div>
							))
						) : (
							<>No Events Yet</>
						)}
					</div>
				</motion.div>
			</motion.div>
			<Testimonials />
			<Footer />
		</motion.div>
	);
};

export default Events;
