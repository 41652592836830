import React from "react";
import AboutImage from "../images/about-us-img.png";
import AboutImageBig from "../images/about-img-big.png";
import SonImage from "../images/son-img.png";
import Shirley from "../images/Shirley.png";
import Barbara from "../images/Barbara.png";
import Paulette from "../images/Paulette.png";
import Ikechukwu from "../images/Ikechukwu.png";
import Serine from "../images/Serine.png";
import Deron from "../images/Deron.png";
import Gavin from "../images/Gavin.png";
import Heather from "../images/Heather.png";
import { Link } from "react-router-dom";
import { RiFacebookFill, RiInstagramFill } from "react-icons/ri";
import { SiTiktok } from "react-icons/si";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import {
	leftVariant,
	rightVariant,
	staggerContainer,
	textVariant,
} from "../utils/motion";

const About = () => {
	return (
		<motion.div
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ type: "spring", ease: "easeIn", duration: 1 }}
		>
			<div className="overflow-hidden relative">
				<img
					src={AboutImage}
					className="h-[50vh] md:h-auto xl:h-[60vh] xl:w-full w-auto object-cover"
					alt="Kids 1st International"
				/>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="mt-10 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4"
				>
					<motion.h1
						variants={textVariant(0.5)}
						className="text-[2rem] text-center lg:text-5xl xl:text-6xl text-white font-semibold uppercase"
					>
						About Us
					</motion.h1>
					<motion.div
						variants={textVariant(0.6)}
						className="mt-2 flex justify-center items-center gap-2"
					>
						<Link to="/" className="text-[#FBC109]">
							Home{" "}
						</Link>
						<span className="text-white/40 block"> {"// About Us"}</span>
					</motion.div>
				</motion.div>
			</div>
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="main__container mt-20 lg:mt-32"
			>
				<motion.div variants={textVariant(0)}>
					<h2 className="text-3xl text-center lg:w-[800px] mx-auto lg:text-4xl text-[#263734] font-semibold mb-5">
						Our single-minded focus to assist, empower and humanize children is
						our greatest strength.
					</h2>
					<p className="text-[#747474] text-center lg:w-[1000px] mx-auto">
						At Kids First International our single-minded focus and unyielding
						dedication to our mission to assist, empower and humanize children
						is our greatest strength.
						<br />
						<br />
						We leverage this devotion by making sure they have the ability to
						attend school without an empty belly calling them back into the
						streets, providing books and educational material that too many of
						their schools lack and giving them assurance that they are NOT
						alone.
						<br />
						<br />
						We focus on providing assistance to youngsters in living conditions
						where the primary caretaker is unable to fully assist the child with
						the essentials for a healthy, secure and productive upbringing.
						<br />
						<br />
						Guaranteeing a safe, nurturing environment, quality education and
						meaningful after-school programs is what we strive for.
					</p>
					<div className="mt-12">
						<img src={AboutImageBig} alt="" />
					</div>
				</motion.div>
			</motion.div>
			{/* SON  */}
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="mt-20 lg:mt-32 py-12 md:py-16 lg:py-32 bg-[#00715D]"
			>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="main__container lg:grid lg:grid-cols-2 lg:justify-center lg:items-center lg:gap-14"
				>
					<motion.div
						variants={leftVariant(0.2)}
						className="w-full overflow-hidden"
					>
						<img src={SonImage} alt="Kids 1st Internation" />
					</motion.div>
					<motion.div
						variants={rightVariant(0.2)}
						className="mt-8 lg:mt-[unset]"
					>
						<h2 className="text-3xl lg:text-4xl text-white font-semibold mb-6 capitalize">
							Kids 1st International, Inc. was founded in 2011 by Shirley
							Ellison.
						</h2>
						<p className="text-white font-light mx-auto">
							She currently presides as President of the organization. Although
							the idea for Kids 1st was originally concieved in 2006, because of
							financial limitations, the dream had to be deferred. However, the
							postponement only heightened the passion and strenghtened the
							commitment she made to God to eventually get it completed. It is
							now that we have been blessed with funding that we can realize
							this wonderful aspiration. With love, pride and joy this
							organization is dedicated to her son, CJ - her wonderful gift from
							God.
						</p>
						<Link
							to="/donate"
							className="bg-[#FDD65B] inline-flex mt-10 text-black font-semibold py-[15px] px-[50px] mr-4"
						>
							Donate Now
						</Link>
					</motion.div>
				</motion.div>
			</motion.div>
			{/* DIRECTORS  */}
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.1 }}
				className="bg-[#F9F5E8] py-20 lg:py-32"
			>
				<motion.div variants={textVariant(0)} className="main__container">
					<span className="uppercase tracking-widest text-sm text-center text-[#D9A503] block font-semibold mb-2">
						Get Involved
					</span>

					<h2 className="text-3xl lg:text-4xl lg:w-[500px] mx-auto text-[#263734] text-center font-semibold mb-8 capitalize">
						Join Us in Making a Difference in a Child’s life
					</h2>

					<div className="md:grid md:grid-cols-2 md:gap-8 lg:gap-12 lg:grid-cols-3">
						<div className="get__involved-article">
							<img src={Shirley} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Shirley McDowell Ellison
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									president
								</span>
								<p className="text-center mt-2 mb-4">
									Shirley was born in Westmoreland and raised in Hanover,
									Jamaica. Graduate of Petersfield High School in Westmoreland.
									Attended business school in Montego Bay, Jamaica. She migrated
									to New York and worked as licensed real estate agent in New
									York and is currently President at Ellison Home Care Companion
									Agency.
								</p>
								<div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://web.facebook.com/kids1stinternational"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.instagram.com/shirleycj4/"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiInstagramFill className="text-white" />
									</Link>
									<Link
										to="https://www.tiktok.com/@shirleyellison2"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<SiTiktok className="text-white" />
									</Link>
								</div>
							</div>
						</div>
						<div className="get__involved-article mt-8 md:mt-[unset]">
							<img src={Ikechukwu} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Ikechukwu Mitchel Ogbonna
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									vice president
								</span>
								<p className="text-center mt-2 mb-4">
									Ikechukwu was raised in Nigeria and obtained a Bachelor's
									Degree in Sociology from the University of Jos. Plateau State.
									He is currently a multi-award winning actor credited with over
									100 movie titles to his name and a Nollywood producer and
									businessman. Amongst other awards he was awarded father of the
									year 2020 and a recognition award by the First Lady of Nigeria
									for contributions to the development of the Nigerian
									entertainment industry.
								</p>
								<div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://www.facebook.com/Officialikogbonna/"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.instagram.com/ikogbonna/"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiInstagramFill className="text-white" />
									</Link>
									<Link
										to="https://www.tiktok.com/@Actorikogbonna"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<SiTiktok className="text-white" />
									</Link>
								</div>
							</div>
						</div>
						<div className="get__involved-article mt-8 lg:mt-[unset]">
							<img src={Barbara} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Barbara Ellison
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									communications/ logistics specialist
								</span>
								<p className="text-center mt-2 mb-4">
									Barbara was born in Hanover, Jamaica and a graduate of Green
									Island High School in Hanover, Jamaica. She currently works as
									Coordinator / In-Service Supervisor at Ellison Home Care
									Companion Agency.
								</p>
								<div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://web.facebook.com/barbara.mcdowellellison"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.instagram.com/ellison2801/"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiInstagramFill className="text-white" />
									</Link>
								</div>
							</div>
						</div>
						<div className="get__involved-article mt-8 lg:mt-[unset]">
							<img src={Paulette} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Paulette McDowell Bailey
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									treasurer{" "}
								</span>
								<p className="text-center mt-2 mb-4">
									Paulette was born in the parish of Hanover, Jamaica. She is a
									Certified Financial Analyst and also served as a Treasurer for
									approximately 10 years. She currently works as Senior
									Coordinator / Care Manager at Ellison Home Care Companion
									Agency.
								</p>
								<div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://web.facebook.com/paulette.mcdowell.3"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.instagram.com/bpaulette2012/"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiInstagramFill className="text-white" />
									</Link>
								</div>
							</div>
						</div>
						<div className="get__involved-article mt-8 lg:mt-[unset]">
							<img src={Serine} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Serine Malcolm
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									asst treasurer
								</span>
								<p className="text-center mt-2 mb-4">
									Serine is a native of Jamaica and has lived in Brentwood, New
									York since 2003. She has been an educational professional with
									extensive experience working with students of all ages. She
									currently works as a Senior Assistant in the office of Student
									Affairs, Office of the Registrar at SUNY Downstate Health
									Sciences University. She currently holds B.S. in Business
									Administration from SUNY Empire State University.
								</p>
								{/* <div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://www.facebook.com"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.facebook.com"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiLinkedinFill className="text-white" />
									</Link>
									<Link
										to="https://www.facebook.com"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiTwitterFill className="text-white" />
									</Link>
								</div> */}
							</div>
						</div>
						<div className="get__involved-article mt-8 lg:mt-[unset]">
							<img src={Deron} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Deron Hill
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									marketing / public relations
								</span>
								<p className="text-center mt-2 mb-4">
									Pastor Hill was born in Montego Bay, Jamaica and spent his
									formative years in Clarendon where he attended High School.
									His passion for ministry inspired and led him to pursue a
									degree in Religion and Theology at the Norther Caribbean
									University, Mandeville, Jamaica. He currently is the senior
									pastor of the Stuyvesant Heights Church in Brooklyn, NY
								</p>
								<div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://web.facebook.com/deron.hill.9"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.instagram.com/iamderonhill/"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiInstagramFill className="text-white" />
									</Link>
								</div>
							</div>
						</div>
						<div className="get__involved-article mt-8 lg:mt-[unset]">
							<img src={Gavin} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Gavin Bailey
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									Event Planner/Communication/Logistic Specialist
								</span>
								<p className="text-center mt-2 mb-4">
									Born in Guyana, South America, Gavin relocated to the United
									States in 1980. With a bachelor's degree, He dedicated 22
									years of his career to the shareholder relations services
									sector. Throughout his professional journey, he gained
									valuable experience in the real estate and radio industries.
									Currently, he have been passionately working in the healthcare
									industry for the past decade, contributing his expertise and
									knowledge to make a positive impact.
								</p>
								{/* <div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://www.facebook.com"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.facebook.com"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiLinkedinFill className="text-white" />
									</Link>
									<Link
										to="https://www.facebook.com"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiTwitterFill className="text-white" />
									</Link>
								</div> */}
							</div>
						</div>
						<div className="get__involved-article mt-8 lg:mt-[unset]">
							<img src={Heather} alt="Kids 1st International" />
							<div className="mt-4">
								<h3 className="font-semibold text-[#263734] text-center text-[1.3rem]">
									Heather McPherson
								</h3>
								<span className="block text-[#747474] text-center text-sm mb-4 capitalize">
									marketing / public relations
								</span>
								<p className="text-center mt-2 mb-4">
									Heather McPherson, a seasoned and proficient nurse, brings an
									exceptional 22 years of valuable experience in the healthcare
									industry. Currently, she serves as a dedicated healthcare
									professional at two renowned institutions, namely Stonybrook
									University Hospital Southampton and Ellison Home Care
									Companion Agency. Originally from St. James, Jamaica, West
									Indies, Heather holds a degree in Herbert Morrison Science in
									Nursing from Hele Fuld College of Nursing and a Bachelor of
									Science in Nursing from Aspen University. Her extensive
									knowledge and expertise make her an asset in providing
									top-notch care to patients.
								</p>
								<div className="flex items-center justify-center gap-5 mt-2">
									<Link
										to="https://web.facebook.com/heather.mcpherson.73594"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiFacebookFill className="text-white" />
									</Link>
									<Link
										to="https://www.instagram.com/needmoreshoe814/"
										target="_blank"
										className="get__involved-icon p-3 flex justify-center items-center rounded-full bg-[#00715D]"
									>
										<RiInstagramFill className="text-white" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</motion.div>
			</motion.div>
			{/* TESTIMONIALS  */}
			<div className="-mt-36">
				<Testimonials />
			</div>

			{/* FOOTER  */}
			<Footer />
		</motion.div>
	);
};

export default About;
