import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/element/css/navigation";
import "swiper/css";
import { testimonials } from "../data";
import { Navigation } from "swiper";
import { motion } from "framer-motion";
import { staggerContainer } from "../utils/motion";
import { textVariant } from "../utils/motion";

const Testimonials = () => {
	const swiperRef = useRef(null);

	return (
		<motion.div
			variants={staggerContainer}
			initial="hidden"
			whileInView="show"
			viewport={{ once: true, amount: 0.25 }}
			className="bg-[#F9F5E8] mt-20 lg:mt-32 py-20 lg:py-32"
		>
			<motion.div
				variants={textVariant(0.2)}
				className="main__container lg:max-w-[800px]"
			>
				<span className="uppercase tracking-widest text-sm text-center text-[#D9A503] block font-semibold mb-2">
					Testimonials
				</span>

				<h2 className="text-3xl lg:text-4xl lg:w-[500px] mx-auto text-[#263734] text-center font-semibold mb-8 capitalize">
					What People Are Saying About Us{" "}
				</h2>
				{/* <div>
					<button id="swipeLeft">
						<BiChevronLeft className="testimonial-vector" />
					</button>
					<button id="swipeRight">
						<BiChevronRight className="testimonial-vector-right" />
					</button>
				</div> */}

				<Swiper
					navigation={true}
					slidesPerView={1}
					speed={500}
					loop={true}
					grabCursor={true}
					cssMode={true}
					modules={[Navigation]}
					className="testimonial__cards mt-20 pb-28 relative"
					ref={swiperRef}
				>
					<span className="block absolute text-[19rem] text-[#E91E63]/20 top-[-8rem] z-[-1] left-[50%] translate-x-[-50%]">
						''
					</span>
					{testimonials.map(({ name, portfolio, comment }, index) => (
						<SwiperSlide
							className="testimonial__card flex justify-center items-center gap-2 mb-24"
							key={index}
						>
							<div className="testimonial__card-profile">
								<div className="testimonial__card-quote text-[1.3rem] lg:text-2xl lg:leading-10 font-semibold text-[#747474] italic text-center">
									{comment}
								</div>
								<div className="profile__data mt-4">
									<h4 className="profile__name text-[#263734] text-center text-lg font-semibold">
										{name}
									</h4>
									<p className="profile__portfolio text-[#747474] text-center text-sm font-light">
										{portfolio}
									</p>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</motion.div>
		</motion.div>
	);
};

export default Testimonials;
