import React, { useEffect, useState } from "react";
import { BiTime } from "react-icons/bi";
import { MdOutlinePinDrop } from "react-icons/md";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
	leftVariant,
	navVariants,
	rightVariant,
	staggerContainer,
} from "../utils/motion";
import client from "../client";

const EventComp = () => {
	const [eventData, setEventData] = useState([]);
	const [latestEvent, setLatestEvent] = useState(null);

	useEffect(() => {
		client
			.fetch(
				`*[_type == "event"]{
          title,
          slug,
          description,
          day,
          month,
          organizer,
          timeDay,
          details,
          locationDate,
          content,
          image{
            asset->{
              _id,
              url
            },
            alt
          }
        }`
			)
			.then((data) => {
				console.log(data);
				setEventData(data.slice(1, 4)); // Set maximum of 3 events, and because I used 1 to 4, it ignores the first event which is 0, and starts from the second event which is 1

				// Get the latest event from the data
				const latest = data[0];
				setLatestEvent(latest);

				// Save the latest event data in local storage
				localStorage.setItem("latestEvent", JSON.stringify(latest));
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		// Retrieve the latest event data from local storage on page load
		const storedEvent = localStorage.getItem("latestEvent");
		if (storedEvent) {
			setLatestEvent(JSON.parse(storedEvent));
		} else {
			setLatestEvent(null); // Clear latestEvent if no event is found in local storage
			localStorage.removeItem("latestEvent"); // Clear the local storage item
		}
	}, []);

	return (
		<motion.div
			variants={staggerContainer}
			initial="hidden"
			whileInView="show"
			viewport={{ once: true, amount: 0.25 }}
			className="mt-20 lg:mt-32"
		>
			<motion.div
				variants={staggerContainer}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				className="main__container"
			>
				<motion.div
					variants={navVariants}
					className="lg:flex lg:justify-between lg:items-center"
				>
					<div>
						<span className="uppercase tracking-widest text-sm text-[#D9A503] block font-semibold mb-2">
							Our Events
						</span>

						<h2 className="text-3xl lg:text-4xl lg:w-[400px] text-[#263734] font-semibold mb-5">
							See Upcoming Events and Webinars
						</h2>
					</div>

					<Link
						to="/events"
						className="bg-[#FDD65B] inline-flex text-black font-semibold py-[15px] px-[70px] mr-4"
					>
						All Events
					</Link>
				</motion.div>

				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="mt-10 lg:mt-14 lg:grid lg:grid-cols-2 lg:gap-16"
				>
					{latestEvent && (
						<motion.div variants={leftVariant(0.2)}>
							<div
								key={latestEvent.slug.current}
								className="mb-10 lg:mb-[unset]"
							>
								<div className="img relative overflow-hidden">
									<Link to={`/events/${latestEvent.slug.current}`}>
										<img
											src={latestEvent.image.asset.url}
											alt={latestEvent.title}
										/>
									</Link>
									<div className="flex flex-col items-center justify-center p-4 bg-[#E91E63] absolute top-0 left-0">
										<span className="block text-center text-white text-4xl font-bold">
											{latestEvent.day}
										</span>
										<span className="block text-center text-white text-[1.2rem] font-medium">
											{latestEvent.month}
										</span>
									</div>
								</div>
								<div className="info mt-4 flex flex-wrap items-center gap-2">
									<p className="text-[#747474] font-medium">
										Organized By:{" "}
										<span className="text-[#E91E63]">
											{latestEvent.organizer}
										</span>
									</p>
									<p className="text-[#747474] flex justify-center items-center gap-2 font-medium uppercase">
										<BiTime size={20} className="text-[#E91E63]" />{" "}
										{latestEvent.timeDay}
									</p>
									<div className="w-full h-[1px] bg-[#747474] mt-4"></div>
								</div>

								<div className="details mt-4">
									<h3 className="event__title text-2xl text-[#263734] font-semibold mb-4">
										{latestEvent.title}
									</h3>
									<p className="event__details text-[#747474]">
										{latestEvent.details}
									</p>
									<div className="venue__date bg-[#F0F0F0] mt-4 p-3 inline-block">
										<p className="flex items-center gap-2 font-semibold">
											<MdOutlinePinDrop size={20} className="text-[#E91E63]" />{" "}
											{latestEvent.locationDate}
										</p>
									</div>
								</div>
							</div>
						</motion.div>
					)}

					<div>
						{/* Render other events */}
						{eventData.map((event) => (
							<motion.div
								variants={rightVariant(0.2)}
								className="mt-12 lg:mt-[unset]"
								key={event.slug.current}
							>
								{/* Render event content */}
								<div>
									<div className="flex items-center gap-3">
										<div className="inline-flex flex-col py-3 px-4 bg-[#00715D]">
											<span className="block text-center text-white text-4xl font-bold">
												{event.day}
											</span>
											<span className="block text-center text-white text-[1.2rem] font-medium">
												{event.month}
											</span>
										</div>
										<div className="details">
											<div>
												<p className="text-[#747474] font-medium">
													Organized By:{" "}
													<span className="text-[#E91E63]">
														{event.organizer}
													</span>
												</p>
											</div>
											<h3 className="event__title text-[1.2rem] text-[#263734] font-semibold mb-2">
												{event.title}
											</h3>
											<p className="event__details text-[#747474]">
												{event.details}
											</p>
										</div>
									</div>
									<div className="w-full h-[1px] bg-[#747474] my-6"></div>
								</div>
							</motion.div>
						))}
					</div>
				</motion.div>
			</motion.div>
		</motion.div>
	);
};

export default EventComp;
