import { createClient } from "@sanity/client";

export const client = createClient({
	projectId: "hhcrzksh",
	dataset: "production",
	useCdn: true,
	apiVersion: "2023-05-19",
});

export default client;
