import React, { useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { BiGridAlt } from "react-icons/bi";
import Logo from "../images/Kids1st-logo.png";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleOpenMenu = () => {
		setIsMenuOpen(true);
	};

	const handleCloseMenu = () => {
		setIsMenuOpen(false);
	};

	return (
		<header
			className="header z-[9999] fixed top-0 left-0 w-full bg-white py-2"
			id="header"
		>
			<nav className="nav__container max-w-[1200px] main__container h-12 md:h-16 lg:h-20 flex justify-between items-center">
				<Link to="/" className="logo">
					<img
						src={Logo}
						alt="Kids 1st International"
						className="w-[90px] md:w-[120px] lg:w-[160px]"
					/>
				</Link>

				<div
					className={`main-nav md:relative fixed lg:relative filter backdrop-blur-sm lg:filter-none lg:backdrop-blur-none bg-[#14141420] lg:bg-transparent top-0 right-[-100%] lg:right-[unset] w-full h-full z-[3] lg:flex lg:justify-center lg:items-center lg:gap-4 lg:ml-16 xl:ml-40 ${
						isMenuOpen ? "show-menu" : ""
					}`}
					id="main-nav"
				>
					<ul className="nav-list lg:text-sm text-white lg:text-[#212121] uppercase font-semibold absolute pt-[6rem] lg:pt-[unset] pr-[0] pb-[3rem] lg:pb-[unset] pl-[3rem] lg:pl-[unset] top-0 right-0 w-[70%] lg:w-full h-screen lg:h-full bg-[#00715D] lg:bg-[transparent] shadow-[-1px 0 4px rgba(14, 55, 63, 0.15)] flex flex-col gap-[3rem] lg:items-center lg:flex-row lg:gap-10">
						{/* <li className="nav-item">
							<NavLink to="/" onClick={handleCloseMenu}>
								Home
							</NavLink>
						</li> */}
						<li className="nav-item">
							<NavLink
								to="/about-us"
								onClick={handleCloseMenu}
								className={({ isActive }) => (isActive ? "active-nav" : "")}
							>
								About us
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								to="/what-we-do"
								onClick={handleCloseMenu}
								className={({ isActive }) => (isActive ? "active-nav" : "")}
							>
								What we do
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								to="events"
								onClick={handleCloseMenu}
								className={({ isActive }) => (isActive ? "active-nav" : "")}
							>
								Events
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								to="get-involved"
								onClick={handleCloseMenu}
								className={({ isActive }) => (isActive ? "active-nav" : "")}
							>
								Get involved
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink
								to="contact-us"
								onClick={handleCloseMenu}
								className={({ isActive }) => (isActive ? "active-nav" : "")}
							>
								Contact
							</NavLink>
						</li>

						<NavLink
							to="donate"
							className="mt-[auto] mb-28 md:mb-[unset] lg:mt-[unset] lg:ml-auto bg-[#FDD65B] md:bg-[#00715D] text-[#212121] md:text-white font-semibold py-[15px] px-[20px]"
							onClick={handleCloseMenu}
						>
							Donate Now
						</NavLink>

						<div
							className="nav-close absolute top-[0.75rem] right-[1.5rem] lg:hidden"
							id="nav-close"
						>
							<RiCloseFill size={30} onClick={handleCloseMenu} />
						</div>
					</ul>
				</div>

				<div className="nav-open lg:hidden" id="nav-open">
					<BiGridAlt size={22} onClick={handleOpenMenu} />
				</div>
			</nav>
		</header>
	);
};

export default Navbar;
