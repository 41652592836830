import Shirley1 from "../src/images/shirley1.png";
import Ikechukwu from "../src/images/Ikechukwu.png";
import Heather1 from "../src/images/heather1.png";
import ServiceImage1 from "../src/images/service-img1.png";
import ServiceImage2 from "../src/images/service-img2.png";
import ServiceImage3 from "../src/images/service-img3.png";
import ServiceImage4 from "../src/images/service-img4.png";
import ServiceImage5 from "../src/images/service-img5.png";
import ServiceImage6 from "../src/images/service-img6.png";

export const volunteer = [
	{
		image: Shirley1,
		name: "Shirley McDowell Ellison",
		fb: "https://www.facebook.com",
		lin: "https://www.linkedin.com",
		tw: "https://www.twitter.com",
	},
	{
		image: Ikechukwu,
		name: "Ikechukwu Mitchel Ogbonna",
		fb: "https://www.facebook.com/Officialikogbonna/",
		in: "https://www.linkedin.com",
		tw: "https://www.twitter.com",
	},
	{
		image: Heather1,
		name: "Heather Comrie",
		fb: "https://www.facebook.com",
		in: "https://www.linkedin.com",
		tw: "https://www.twitter.com",
	},
];

export const services = [
	{
		image: ServiceImage1,
		title: "Education for All",
		desc: "Kids 1st International is a global non-profit organization. We believe every child has the right to a quality education, with the capacity to grow up in a safe and inclusive environment.",
	},
	{
		image: ServiceImage2,
		title: "Aiding Remote Children",
		desc: "Kids 1st has international contacts working with overseas governments to deliver aid, support and supplies to the neediest of children in the most remote location worldwide.",
	},
	{
		image: ServiceImage3,
		title: "Quality Learning for Every Child",
		desc: "Kids 1st   believes that every child has the right to learn. We are working around the world supporting quality learning for every girl and boy, especially those in greatest danger of being left behind.",
	},
	{
		image: ServiceImage4,
		title: "Emergency Aid for Children",
		desc: "Kids 1st is on the ground before, during and after emergencies, working to reach children and families with lifesaving aid and long-term assistance.",
	},
	{
		image: ServiceImage5,
		title: "Potential for All Children",
		desc: "Kids 1st believes every child has the right to fulfil his and her potential.",
	},
	{
		image: ServiceImage6,
		title: "Lifesaving Supplies for Children",
		desc: "Lifesaving Supplies for Children",
	},
];

export const testimonials = [
	{
		name: "Hiroshi Tanaka",
		portfolio: "Social Worker",
		comment: `"As a social worker, I have witnessed the remarkable work Kids 1st International does in supporting Asian children. Their commitment to providing quality education, healthcare, and other essential resources is truly commendable. They understand the unique challenges faced by children in our communities and have tailored their programs to address those needs. I have seen students who were once struggling academically thrive under their guidance. Kids 1st International is a beacon of hope for Asian children, and I wholeheartedly recommend supporting their cause."`,
	},
	{
		name: "Emily Wilson",
		portfolio: "Volunteer and Parent",
		comment: `"As both a dedicated volunteer and a parent, I have had the privilege of working closely with Kids 1st International. Their commitment to improving the lives of children is truly inspiring. As a volunteer, I have witnessed the organization's tireless efforts to provide educational support, healthcare services, and other crucial resources to children in need. Their programs create opportunities for growth, learning, and a brighter future. As a parent, I have seen firsthand the positive impact Kids 1st International has had on my child and many others. They have fostered a nurturing environment that instills confidence, resilience, and a love for learning. Kids 1st International is a game-changer, and I wholeheartedly recommend supporting their vital work."`,
	},
	{
		name: "Kwame Osei",
		portfolio: "Teacher",
		comment: `"As an educator, I deeply appreciate the work Kids 1st International does in supporting African children. They understand the importance of education and provide vital resources to help children succeed academically. Their commitment to empowering young minds and nurturing their talents is commendable. I have witnessed students transform from shy and uncertain to confident and accomplished individuals under their guidance. Kids 1st International is making a significant impact on the lives of African children, and I wholeheartedly endorse their efforts."`,
	},
	{
		name: "Amina Njoku",
		portfolio: "Nurse",
		comment: `"Working as a nurse in Africa, I have encountered many children who lack access to proper healthcare. This charity is a game-changer in that regard. They go the extra mile to provide medical assistance and improve the overall well-being of African children. Their dedication to ensuring that every child receives the care they deserve is inspiring. I have witnessed firsthand the positive impact they have had on the lives of countless children. This charity is truly making a difference, and I encourage everyone to contribute to their mission."`,
	},
	{
		name: "Sarah Johnson",
		portfolio: "Teacher",
		comment: `"As an elementary school teacher, I have witnessed firsthand the incredible impact that Kids 1st International has on the lives of children. They go above and beyond to provide essential support in areas such as education and health, ensuring that every child has a fair chance to succeed. The dedication and commitment of the Kids 1st International team are truly inspiring. I have seen students who were once struggling academically flourish with the assistance they received. Kids 1st International is a ray of hope for children, and I wholeheartedly recommend supporting their cause."`,
	},
	{
		name: "Pierre Dubois",
		portfolio: "Chef",
		comment: `"As a chef, I believe in the importance of nourishing both the body and the mind. Kids 1st International understands the significance of healthy meals in a child's development. By providing nutritious and balanced meals to underprivileged children, Kids 1st International ensures that they have the fuel they need to thrive academically and socially. I have witnessed firsthand the positive impact of their nutrition programs on children's overall well-being. Kids 1st International is making a tangible difference in the lives of these young individuals, and I wholeheartedly support their mission."`,
	},
	{
		name: "Ling Chen",
		portfolio: "Entrepreneur",
		comment: `"As an entrepreneur, I firmly believe in giving back to the community and supporting causes that make a real difference. Kids 1st International for children has impressed me with their comprehensive approach to addressing the needs of Asian children. They not only provide education and healthcare but also focus on fostering creativity, resilience, and entrepreneurial skills. By empowering children with these tools, Kids 1st International is nurturing future leaders and innovators. I wholeheartedly endorse Kids 1st International's mission and urge everyone to contribute to their impactful work."`,
	},
	{
		name: " Olivia Mitchell",
		portfolio: "Artist",
		comment: `"As an artist, I understand the power of creativity in nurturing the minds and souls of children. Kids 1st International recognizes the importance of art and culture in the lives of young ones. Their programs provide access to artistic experiences, mentoring, and resources that allow children to explore their creativity and express themselves. I have seen firsthand the joy and transformation that art brings to these children's lives. Kids 1st International is a catalyst for their dreams and aspirations, and I wholeheartedly support their endeavors."`,
	},
];
