import React from "react";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import DonateImage from "../images/donate-img.png";
import QrCode from "../images/QR-Code.png";
import CashApp from "../images/cash-app.png";
import { motion } from "framer-motion";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { staggerContainer, textVariant } from "../utils/motion";
import { Link } from "react-router-dom";

const Donate = () => {
	const createOrder = (data, actions) => {
		// Retrieve the donation amount from the input field or any other source
		const donationAmount = document.getElementById("donation-amount").value;

		// Implement your createOrder logic here
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: donationAmount,
					},
				},
			],
		});
	};

	const onApprove = (data, actions) => {
		// Implement your onApprove logic here
		return actions.order.capture().then(function (details) {
			// Handle the successful payment
			console.log("Payment completed successfully:", details);
		});
	};

	const onError = (error) => {
		// Implement your onError logic here
		console.log("An error occurred during the payment:", error);
	};

	const onCancel = (data) => {
		// Implement your onCancel logic here
		console.log("Payment was cancelled:", data);
	};

	return (
		<PayPalScriptProvider
			options={{
				"client-id":
					"AR6GOGKwzzZpQrGaOHgNMf1yzIFoRQtEfhLWvwuNL6qPD1UY6c0RiOsFao_g8T_Qn1-ChTJNyh7U30_J",
			}}
		>
			<motion.div
				exit={{ opacity: 0 }}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ type: "spring", ease: "easeIn", duration: 1 }}
			>
				<div className="overflow-hidden relative">
					<img
						src={DonateImage}
						className="h-[50vh] md:h-auto xl:h-[80vh] xl:w-full w-auto object-cover"
						alt="Kids 1st International"
					/>
					<motion.div
						variants={staggerContainer}
						initial="hidden"
						whileInView="show"
						viewport={{ once: true, amount: 0.25 }}
						className="mt-10 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4"
					>
						<motion.h1
							variants={textVariant(0.5)}
							className="text-[2rem] text-center lg:text-5xl xl:text-6xl text-white font-semibold uppercase"
						>
							Donate
						</motion.h1>
						<motion.div
							variants={textVariant(0.6)}
							className="mt-2 flex justify-center items-center gap-2"
						>
							<Link to="/" className="text-[#FBC109]">
								Home{" "}
							</Link>
							<span className="text-white/40 block"> {"// Donate"}</span>
						</motion.div>
					</motion.div>
				</div>
				<motion.div
					variants={staggerContainer}
					initial="hidden"
					whileInView="show"
					viewport={{ once: true, amount: 0.25 }}
					className="mt-20 lg:mt-32"
				>
					<motion.div variants={textVariant(0)} className="main__container">
						<div className="">
							<div>
								<span className="uppercase tracking-widest text-sm text-[#D9A503] block text-center font-semibold mb-2">
									Donation
								</span>

								<h2 className="text-3xl lg:text-4xl lg:w-[500px] mx-auto text-[#263734] text-center font-semibold mb-5">
									You can make a difference in a kid’s life today
								</h2>

								<p className="text-[#747474] text-center lg:w-[1000px] mx-auto">
									Kids First International, Inc. is geared towards demonstrating
									exceptional financial assistance when it comes to assisting
									those who matter most to us - our precious children in need.
									Every little bit helps. Nothing is turned away and everything
									- no matter how small it may be - is appreciated. We're
									impassioned about putting every cent you give to good use. At
									anytime ask us how your donation was used and we will be more
									than happy to show you exactly how.
								</p>
							</div>
						</div>
						<div className="md:flex mt-14 lg:mt-20 md:justify-center md:items-start md:gap-8 lg:gap-16">
							<div className="paypal">
								<h2 className="text-2xl lg:text-3xl text-[#263734] font-semibold">
									Donate Now
								</h2>
								<p className="text-[#747474] mb-8">Help us make a difference</p>
								<div className="flex justify-center items-center mb-6">
									<div className="text-3xl font-semibold bg-[#00715D] py-3 px-4 lg:px-4 lg:py-3 text-white">
										$
									</div>
									<input
										type="number"
										id="donation-amount"
										placeholder="0"
										className="text-3xl p-3 lg:px-4 lg:py-3 bg-[#F2F2F2] w-full font-semibold outline-none border-none"
									/>
								</div>

								<PayPalButtons
									createOrder={createOrder}
									onApprove={onApprove}
									onError={onError}
									onCancel={onCancel}
									style={{ layout: "vertical" }}
								/>
							</div>

							<div className="qr__code mt-14 md:mt-0">
								<h2 className="text-2xl lg:text-3xl text-[#263734] font-semibold mb-8">
									Scan QR-Code Instead
								</h2>

								<img
									src={QrCode}
									alt="Kids 1st International"
									className="w-full lg:w-[70%]"
								/>
							</div>
						</div>
						<Link to="https://cash.app/$kidsfirst24" target="_blank">
							<img
								src={CashApp}
								alt="Kids 1st International"
								className="w-full mt-10 lg:w-[40%] mx-auto"
							/>
						</Link>
					</motion.div>
				</motion.div>

				<Testimonials />
				<Footer />
			</motion.div>
		</PayPalScriptProvider>
	);
};

export default Donate;
